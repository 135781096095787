import { Injectable,NgZone } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, catchError, filter, map, retry, tap, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { jwtDecode } from 'jwt-decode';
import { UserCreateDto } from '../../authentication/dtos/user_create.dtos';

export interface User {
  user:{
    createAt: Date
    email: string
    id: number
    name: string
  }
}
export interface UpdateUser {
    email: string
    name: string
}
export interface UpdatePass {
  currentPassword: string
  newPassword: string
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authState: any;
  afAuth: any;
  afs: any;
  public showLoader:boolean=false;
  private url = environment.apiUrl;
  currentUser$: Observable<User | null>;
  private currentUserSubject: BehaviorSubject<User | null>;

  constructor(private afu: AngularFireAuth, private router: Router,public ngZone: NgZone, private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User | null>(null);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.afu.authState.subscribe((auth: any) => {
      this.authState = auth;
    });
  }

  // all firebase getdata functions

  get isUserAnonymousLoggedIn(): boolean {
    return this.authState !== null ? this.authState.isAnonymous : false;
  }

  get currentUserId(): string {
    return this.authState !== null ? this.authState.uid : '';
  }

  get currentUser(): User | null {
    return this.currentUserSubject.value;
  }

  set currentUser(user: User | null) {
    this.currentUserSubject.next(user);
  }

  get isUserEmailLoggedIn(): boolean {
    if (this.authState !== null && !this.isUserAnonymousLoggedIn) {
      return true;
    } else {
      return false;
    }
  }

  getProfile(): Observable<User> {
    return this.http.get<User>(`${this.url}/user-profile`).pipe(
      map((response) => {
        this.currentUser = response // Store the fetched user data
        return response;
      }),
      catchError((error) => {
        console.error('Error fetching profile:', error);
        return throwError(() => new Error('Error fetching profile'));
      }),
    );
  }

  updateUser(user: UpdateUser): Observable<UpdateUser> {
    return this.http.put<UpdateUser>(`${this.url}/user-profile`, user).pipe(
      tap((response) => {
        // Update the stored user data
        if (this.currentUser) {
          this.currentUser = { ...this.currentUser, ...response };
        }
      }),
      map((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error updating user:', error);
        return throwError(() => new Error('Error updating user'));
      }),
    );
  }
  updatePass(user: UpdatePass): Observable<UpdatePass> {
    return this.http.put<UpdatePass>(`${this.url}/update-password`, user).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        console.error('Error updating user:', error);
        return throwError(() => new Error('Error updating user'));
      }),
    );
  }

  getToken(): string | null {
    return localStorage.getItem('accessToken');
  }

  registerWithEmail(email: string, password: string) {
    return this.afu
      .createUserWithEmailAndPassword(email, password)
      .then((user: any) => {
        this.authState = user;
      })
      .catch((_error: any) => {
        console.log(_error);
        throw _error;
      });
  }

  loginWithEmail(email: string, password: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  
    const body = { email, password };
  
    return this.http.post(`${this.url}/auth/login`, body, { headers }).pipe(
      map((response: any) => {
        // Lưu trữ accessToken và refreshToken khi đăng nhập thành công
        localStorage.setItem('accessToken', response.accessToken);
        localStorage.setItem('refreshToken', response.refreshToken);
        localStorage.setItem('userId', response.userId);
        const expirationTime = new Date().getTime() + (response.expiresIn * 1000);
        localStorage.setItem('expiresAt', expirationTime.toString());
        return response;
      }),
      catchError(error => {
        console.error('Login error:', error);
        throw error;
      })
    );
  }
  refreshToken(): Observable<any> {
    const refreshToken = localStorage.getItem('refreshToken');
    return this.http.post(`${this.url}/auth/refresh/token`, { refresh_token:refreshToken })
      .pipe(
        tap((response:any) => {
          localStorage.setItem('accessToken', response.accessToken);
          localStorage.setItem('refreshToken', response.refreshToken);
          localStorage.setItem('userId', response.userId);
          const expirationTime = new Date().getTime() + (response.expiresIn * 1000);
          localStorage.setItem('expiresAt', expirationTime.toString());
        }),
        catchError(error => {
          console.error('Refresh token error:', error);
          this.router.navigate(['/auth/login']);
          return throwError(error);
        })
      );
  }

  isLoggedIn(): boolean {
    const token = localStorage.getItem('accessToken');
    const expiration = localStorage.getItem('expiresAt');
    const currentDate = new Date();
    const expirationDate = new Date(Number(expiration));
    return !!token;
   
  }

  getTokenExpirationDate(token: string): Date | null {
    const decoded = jwtDecode(token);
    if (decoded.exp === undefined) return null;
    const date = new Date(0); 
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    token = token ?? localStorage.getItem('accessToken') ?? undefined;
    if (!token) return true;
  
    const expiresAt = localStorage.getItem('expiresAt') ?? undefined;
    if (!expiresAt) return true;
  
    const currentDate = new Date();
    const expirationDate = new Date(Number(expiresAt));
    return currentDate.getTime() >= expirationDate.getTime();
  }
    

  logout(): void {
    this.clearLocalStorage(); // Xóa thông tin khỏi local storage
    this.router.navigate(['/auth/login']); // Chuyển hướng người dùng về trang đăng nhập
  }

  private clearLocalStorage(): void {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('userId');
  }

  signUp(data:UserCreateDto): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(`${this.url}/auth/signup`, data, { headers }).pipe(
      map((response: any) => {
        // Handle the response, e.g., storing tokens or user data if needed
        console.log('Signup successful', response);
        return response;
      }),
      catchError(error => {
        console.error('Signup error:', error);
        return throwError(error); // Rethrow the error so subscribers can handle it
      })
    );
  }
  

    // Sign up with email/password
    // SignUp(email:any, password:any) {
    //   return this.afAuth.createUserWithEmailAndPassword(email, password)
    //     .then((result:any) => {
    //       /* Call the SendVerificaitonMail() function when new user sign
    //       up and returns promise */
    //       this.SendVerificationMail();
    //       this.SetUserData(result.user);
    //     }).catch((error:any) => {
    //       window.alert(error.message)
    //     })
    // }


    // main verification function
    // SendVerificationMail() {
    //   return this.afAuth.currentUser.then((u:any) => u.sendEmailVerification()).then(() => {
    //       this.router.navigate(['/dashboard']);
    //     })
    // }
      // Set user
  // SetUserData(user:any) {
  //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
  //   const userData: User = {
  //     email: user.email,
  //     displayName: user.displayName,
  //     uid: user.uid,
  //     photoURL: user.photoURL || 'src/favicon.ico',
  //     emailVerified: user.emailVerified
  //   };
  //   userRef.delete().then(function () {})
  //         .catch(function (error:any) {});
  //   return userRef.set(userData, {
  //     merge: true
  //   });
  // }
 // sign in function
//  SignIn(email:any, password:any) {
//   return this.afAuth.signInWithEmailAndPassword(email, password)
//     .then((result:any) => {
//       if (result.user.emailVerified !== true) {
//         this.SetUserData(result.user);
//         this.SendVerificationMail();
//         this.showLoader = true;
//       } else {
//         this.showLoader = false;
//         this.ngZone.run(() => {
//           this.router.navigate(['/auth/login']);
//         });
//       }
//     }).catch((error:any) => {
//       throw error;
//     })
// }
ForgotPassword(passwordResetEmail:any) {
  return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      window.alert('Password reset email sent, check your inbox.');
    }).catch((error:any) => {
      window.alert(error);
    });
}
}
